let origin = window.ORIGIN;
const getIsBindingPersonsUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getIsBindingPersons`; //获取当前用户绑定的老人信息
const getMyCartUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getMyCart`; //获取我的购物车
const orderFoodUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/orderFood`; //下单
const getFoodDetailsUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getFoodDetails`; //获取菜品详情
const checkIsBindingUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/checkIsBinding`; //判断当前用户是否绑定过老人
const deleteCartFoodUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/deleteCartFood`; //删除购物车商品
const getFoodListByTypeUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getFoodListByType`; //根据菜品类别获取菜品列表
const changeOrderStatusUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/changeOrderStatus`; //取消/完成订单
const getOrderListUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getOrderList`; //获取订单列表/工作人员
const addCartUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/addCart`; //加入购物车
const getMyOrderListUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getMyOrderList`; //获取我的订单
const clearMyCartUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/clearMyCart`; //清空购物车
const bindingPensonUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/bindingPenson`; //老人认证
const getOrderDetailsUrl = `${origin}/gateway/hc-health/miniapi/healthHeart/getOrderDetails`; //获取订单详情
const getFoodTypeUrl = `${origin}/gateway/hc-health/manage/healthHeart/getFoodType`; //获取菜品类别
const userAssetListUrl = `${origin}/gateway/hc-mini/user/mini/user-asset-list`; //获取用户房屋信息
const getFunctionSettingUrl = `${origin}/gateway/blade-system/application/getFunctionSetting`; //获取权限
// 获取用户个人信息
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;
export {
  getIsBindingPersonsUrl,
  getMyCartUrl,
  orderFoodUrl,
  getFoodDetailsUrl,
  checkIsBindingUrl,
  deleteCartFoodUrl,
  getFoodListByTypeUrl,
  changeOrderStatusUrl,
  getOrderListUrl,
  addCartUrl,
  getMyOrderListUrl,
  clearMyCartUrl,
  bindingPensonUrl,
  getOrderDetailsUrl,
  getFoodTypeUrl,
  userAssetListUrl,
  getFunctionSettingUrl,
  userInfoUrl,
};
