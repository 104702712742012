<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <img src="./img/yellow-bg.png" class="bg" />
    <div class="main">
      <div class="chooseMen" @click="show = true">
        <div class="left">选择就餐人</div>
        <div class="right">
          {{ receiverName }}
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="chooseMethod">
        <div class="left" @click="toPath('payFoodOrder', '外送')">
          <img src="./img/ws.png" alt="" />
          <div>外送</div>
        </div>
        <div class="right" @click="toPath('payFoodOrder', '堂食')">
          <img src="./img/ts.png" alt="" />
          <div>堂食</div>
        </div>
      </div>
      <div class="orderDetail">
        <div class="orderDetail-top">
          <div class="title">订单详情</div>
        </div>
        <div class="orderDetail-center">
          <div class="item" v-for="(item, index) in dialogList" :key="index">
            <div class="item-left">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-right">
              <div class="name">
                <div class="name-txt">{{ item.foodName }}</div>
                <div class="price"><span>¥</span>{{ item.countPrice }}</div>
              </div>
              <div class="num">x1</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-picker
      :columns="infoList"
      :valueShow="show"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import { getIsBindingPersonsUrl, getMyCartUrl } from "./api";
export default {
  name: "chooseDiningMethod",
  data() {
    return {
      infoList: [],
      dialogList: [],
      show: false,
      eventKey: "text",
      oldPersonId: null,
      receiverName: null,
      receiverPhone: null,
      isOld: localStorage.getItem("isOld"),
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    console.log(localStorage.getItem("isOld"));
    this.getInfoList();
    this.getDialogList();
  },
  methods: {
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      this.oldPersonId = value.id;
      this.receiverName = value.text;
      this.receiverPhone = value.mobile;
      console.log(value);
      this.show = false;
    },
    getDialogList() {
      let params = {
        userId: this.userId,
      };
      this.$axios.get(`${getMyCartUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            if (item.image) {
              item.image = handleImg(136, 136, item.image);
            }
          });
          this.dialogList = res.data;
        }
      });
    },
    getInfoList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getIsBindingPersonsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              item.text = item.personName;
              this.infoList.push(item);
            });
            this.oldPersonId = this.infoList[0].id;
            this.receiverName = this.infoList[0].text;
            this.receiverPhone = this.infoList[0].mobile;
          }
        });
    },
    toPath(name, type) {
      this.$router.push({
        name: name,
        query: {
          type: type,
          oldPersonId: this.oldPersonId,
          receiverName: this.receiverName,
          receiverPhone: this.receiverPhone,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 22px;
  box-sizing: border-box;
  background: #f9f9f9;
  .main {
    padding: 0 30px;
    box-sizing: border-box;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 38px;
          .item-right {
            flex: 1;
            padding: 12px 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 24px;
                }
              }
            }
          }
          .item-left {
            width: 136px;
            height: 136px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .chooseMethod {
      width: 100%;
      height: 232px;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      img {
        width: 88px;
        display: block;
        margin: 0 auto;
      }
      .right {
        text-align: center;
        width: 316px;
        height: 196px;
        background: #e7f2fa;
        border-radius: 16px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #488cff;
        box-sizing: border-box;
        padding-top: 26px;
      }
      .left {
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fca315;
        margin-right: 18px;
        width: 316px;
        height: 196px;
        background: #fef8e7;
        border-radius: 16px;
        box-sizing: border-box;
        padding-top: 26px;
      }
    }
    .chooseMen {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px;
      height: 136px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
      position: relative;
      .right {
        display: flex;
        width: 400px;
        height: 62px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        box-sizing: border-box;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        img {
          width: 28px;
        }
      }
      .left {
        flex: 1;
      }
    }
  }

  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
.isOld {
  .main {
    padding: 0 30px;
    box-sizing: border-box;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .title {
        font-size: 46px;
      }
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 48px;
          .item-right {
            flex: 1;
            padding: 0 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 42px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 48px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 34px;
                }
              }
            }
          }
          .item-left {
            width: 156px;
            height: 156px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .chooseMethod {
      width: 100%;
      height: 262px;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      img {
        width: 108px;
        display: block;
        margin: 0 auto;
      }
      .right {
        text-align: center;
        width: 316px;
        height: 206px;
        background: #e7f2fa;
        border-radius: 16px;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #488cff;
        box-sizing: border-box;
        padding-top: 26px;
      }
      .left {
        text-align: center;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fca315;
        margin-right: 18px;
        width: 316px;
        height: 216px;
        background: #fef8e7;
        border-radius: 16px;
        box-sizing: border-box;
        padding-top: 26px;
      }
    }
    .chooseMen {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px;
      height: 136px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      font-size: 46px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
      position: relative;
      .right {
        display: flex;
        width: 400px;
        height: 62px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        box-sizing: border-box;
        font-size: 42px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        img {
          width: 28px;
        }
      }
      .left {
        flex: 1;
      }
    }
  }
}
</style>
